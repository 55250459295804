@import '../../../styles/_variables';

.evaluationWrapper {
  .scoreAlert {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: 30px;
    z-index: 1000;
  }

  .titleWrapper {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;

    .evaluationTitle {
      padding: 30px 0 15px 0;

      .heading {
        font-size: $h2-font-size;
        font-family: $h2-font-family;
        font-weight: 500;
        text-transform: uppercase;
      }

      .impersonationInfo {
        display: block;
        font-size: $copy-l-font-size;
        font-family: $copy-l-font-family;
        line-height: $copy-l-line-height;
        font-weight: $font-weight-bold;
      }
    }

    .expandIcon {
      margin-left: 20px;
      color: $primary-red;
      width: 33px;
      height: 33px;
      cursor: pointer;
    }
  }

  .rightColum {
    display: flex;
    align-items: center;
  }

  .totalScore {
    width: 165px;
    height: 60px;
    background-color: $primary-red;
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      color: $primary-white;
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      font-weight: 500;
      word-spacing: 100px;
    }

    .score {
      font-size: $h1-font-size;
      font-family: $h1-font-family;
      font-weight: 700;
      color: $primary-white;
    }

    &.preselection {
      background-color: $primary-white;
      width: 170px;

      .label,
      .score {
        color: $secondary-darkgrey;
      }
    }
  }

  .transferScoresButtonContainer {
    margin-right: 20px;
    margin-left: 8px;

    .transferScoresButton {
      color: $primary-red;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .evaluationList {
    width: 100%;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }

    .loadingOverlay {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 3333;
      color: $primary-red;
    }
  }
}
