@import '../../styles/_variables';
@import '~bootstrap/scss/bootstrap';

.navigation {
  .navigationContainer {
    height: $navigation-height;
  }
  &.admin {
    background: $primary-blue;
  }

  &.juror {
    background: $primary-red;
  }
}

.navigationInner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: $primary-white;
  font-size: $copy-font-size;
  line-height: 1.43;
  align-items: center;

  .navigationCenter {
    display: flex;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 0;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      .navTitle {
        margin: 0;
        font-weight: 700;
        font-family: $copy-font-family;
        font-size: $copy-font-size;
      }
      .juryPhase {
        margin-left: 10px;
        font-weight: 500;
        font-family: $copy-font-family;
        font-size: $copy-font-size;
      }
    }
  }
  .navigationLeft {
    flex-direction: row;
    align-items: center;
    display: flex;
    z-index: 1;

    .logoContainer {
      display: flex;
      color: $primary-white;
      font-family: 'Suisse Intl', sans-serif;
      font-size: $copy-font-size;
      line-height: 1.43;
      text-decoration: none;
      align-items: center;
      text-transform: uppercase;
      margin-right: 100px;
      white-space: nowrap;
      .logo {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
    .navContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .navLink {
        color: $primary-white;
        font-family: 'Suisse Intl', sans-serif;
        font-size: $copy-font-size;
        line-height: 1.43;
        text-decoration: none;
        text-transform: uppercase;
        a {
          color: $primary-white;
        }

        &:not(last-of-type) {
          margin-right: 60px;
        }

        .dropdownItem {
          height: 42px;
          background: #fff;
          color: $primary-blue;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 0;
          border: none;
          border-bottom: solid 1px #939292;

          &:hover {
            background: #eee;
            transition: 0.1s;
          }

          &.disabled,
          &:disabled {
            .dropdownItemLink {
              color: rgba($primary-blue, 0.5);
            }
          }

          .dropdownItemLink {
            color: $primary-blue;
            padding: 0 16px;
            text-decoration: none;
            height: 100%;
            width: 100%;
            justify-content: flex-start;
            display: flex;
            align-items: center;
          }
        }
        .navIcon {
          margin-right: 10px;
          width: 12px;
        }
      }
    }
  }

  .navigationRight {
    display: flex;

    .scanLink {
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 0 20px 0 10px;

      .qrIcon {
        height: 30px;
        width: 30px;
      }
    }

    .userInfo {
      display: flex;
      align-items: center;

      .userName {
        font-family: $copy-xs-font-family;
        font-size: $copy-xs-font-size;
        line-height: $copy-xs-line-height;
        color: $primary-white;
        margin-right: 1rem;
      }

      .userDropdown {
        margin-right: -1rem;
        .dropdown-toggle {
          padding: 0;
        }
      }

      .userActions {
        height: 42px;
        background: #fff;
        color: $primary-blue;
        padding: 0 0 0 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0;
        border: none;
        border-bottom: solid 1px #939292;

        .logoutIcon {
          margin-right: 8px;
        }
      }

      .userAvatar {
        width: 32px;
        height: 32px;
        background-color: $primary-white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;

        .avatarIcon {
          color: $primary-blue;
          width: 16px;
          height: 16px;
        }
      }
    }

    .hamburgerMenu {
      z-index: 1337;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 16px;
      .burgerIcon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.mobileNavigation {
  top: $navigation-height;
  right: 0;
  bottom: 0;
  left: 0;
  background: $primary-blue;
  position: absolute;
}
