$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1194px,
  xxl: 1440px,
);

// ------ Colors ------
//primary
$primary-red: #ed1d26;
$primary-black: #000000;
$primary-white: #ffffff;
$primary-blue: #21364e;

//secondary
$secondary-darkgrey: #8b8b8b;
$secondary-grey: #b5b5b5;
$secondary-lightgrey: #efefef;
$secondary-lightgrey-alt: #f5f5f5;
$secondary-darkbeige: #ada191;
$secondary-lightbeige: #f9f9f8;
$secondary-blue: #5a697b;
$secondary-lightblue: #dbe3ea;

//tertiary
$tertiary-petrol: #009688;
$tertiary-yellow: #ffde03;
$tertiary-pink: #e60046;
$tertiary-gold: #c48f03;

$dark-blue: #21364e;

$navigation-height: 50px;
// ------ Typography ------
//font-family
$suisse-int: 'Suisse Intl', sans-serif;

//font-weight
$font-weight-light: 300;
$font-weight-regular: normal;
$font-weight-bold: bold;

//h1
$h1-font-family: $suisse-int;
$h1-font-size: 36px;
$h1-line-height: 1.33;

//h2
$h2-font-family: $suisse-int;
$h2-font-size: 22px;
$h2-line-height: 1.27;

//h3
$h3-font-family: $suisse-int;
$h3-font-size: 18px;
$h3-line-height: 1.44;

//copy-big
$copy-l-font-family: $suisse-int;
$copy-l-font-size: 16px;
$copy-l-line-height: 1.5;

//copy
$copy-font-family: $suisse-int;
$copy-font-size: 14px;
$copy-line-height: 1.43;

//copy-small
$copy-s-font-family: $suisse-int;
$copy-s-font-size: 12px;
$copy-s-line-height: 1.5;

//copy-extra-small
$copy-xs-font-family: $suisse-int;
$copy-xs-font-size: 10px;
$copy-xs-line-height: 1.4;

//button-1
$button-1-font-family: $suisse-int;
$button-1-font-size: 14px;
$button-1-line-height: 1.43;
$button-1-letter-spacing: 1px;

//button-2
$button-2-font-family: $suisse-int;
$button-2-font-size: 16px;
$button-2-line-height: 1.25;
$button-2-letter-spacing: 1.7px;

:export {
  secondaryBlue: $secondary-blue;
  secondaryLightBlue: $secondary-lightblue;
  tertiaryPetrol: $tertiary-petrol;
  secondaryGrey: $secondary-grey;
  secondaryLightGrey: $secondary-lightgrey;
}
