@import '../../styles/_variables';

.search {
  position: relative;
  width: 258px;
  height: 36px;
  padding: 6px 7px 7px 30px;
  border-radius: 18px;
  background-color: #efefef;

  .input {
    background-color: transparent;
    border: 0;
    outline: none;
    width: 100%;
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
