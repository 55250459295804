@import '../../../styles/_variables';

.taskContainer {
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  position: relative;
  display: flex;
  flex-direction: row;
  height: 322px;

  .image {
    width: 48%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.empty {
      background-size: 100px;
    }
  }

  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px
  }

  .textContainer {
    flex-grow: 2;
    padding: 25px 20px 35px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .awardTitle {
      width: fit-content;
      margin-bottom: 0;
      display: inline-block;
      font-family: 'SuisseIntlCond';
      font-size: $h1-font-size;
      line-height: $h2-line-height;
      font-weight: $font-weight-bold;

      .placeholder {
        width: 200px;
      }
    }

    .metaDataContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .progressText,
      .categoryText {
        font-family: $h2-font-family;
        font-size: $h2-font-size;
        line-height: 1.1;
      }

      .progressText {
        font-weight: $font-weight-bold;
      }
    }

    .bottomRowContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .smallTextContainer {
        .smallText {
          display: block;
          width: fit-content;
          font-family: $copy-font-family;
          font-size: $copy-font-size;
          line-height: $copy-line-height;

          &:first-child {
            margin-bottom: 3px;
          }

          svg {
            padding-right: 8px;
          }
        }
      }

      .button {
        position: initial;
        padding-left: 0;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: '';
        }

        span {
          top: auto;
          bottom: auto;
          right: auto;
          left: auto;
          padding-right: 15px;
        }
      }
    }
  }

  .separate {
    position: relative;
  }
}
