@import '../../../styles/_variables';

.dashboard {
  height: 100%;
  background-color: $secondary-lightbeige;

  .content {
    margin-top: 30px;

    .row {
      align-items: stretch;

      .widget {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        .title {
          font-family: $copy-xs-font-family;
          font-size: $copy-xs-font-size;
          line-height: $copy-xs-line-height;
          font-weight: $font-weight-bold;
          margin-bottom: 11px;
          text-transform: uppercase;
        }
      }
    }
  }
}
