@import '../../styles/_variables';

.wrapper {
  position: relative;
  width: 120px;
  height: 120px;

  &.withSubtitle {
    width: 135px;
    height: 135px;
  }

  .textWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      font-family: $h1-font-family;
      font-size: $h1-font-size;
      line-height: $h1-line-height;
      font-weight: $font-weight-bold;
    }
  }
  .subtitleWrapper {
    position: absolute;
    bottom: 28px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .subtitle {
      font-family: $copy-s-font-family;
      font-size: $copy-s-font-size;
      line-height: $copy-s-line-height;
      font-weight: $font-weight-bold;
    }
  }
}
