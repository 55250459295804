@import '../../styles/_variables';

.progressBar {
  background-color: var(--bgColor, $secondary-lightgrey);
  height: 11px;
  margin: 3px 0;
  position: relative;
  width: 100%;
}

.progress {
  background-color: var(--color, $primary-red);
  height: 100%;
  width: var(--progress, 50%);
}

.marker {
  background-color: var(--color, $secondary-grey);
  position: absolute;
  left: 50%;
  top: -3px;
  transform: translateX(-50%);
  height: 17px;
  width: 4px;
}
