@import '../../../../styles/_variables';
@import '~bootstrap/scss/bootstrap';

.statisticContainer {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;

  &.doubleRows {
    grid-template-rows: 1fr 1fr;
  }

  &.finalJury {
    grid-template-columns: repeat(12, 1fr);

    .column {
      grid-column-end: span 4;
    }
  }

  .column {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 10px;

    &:has(.subValue) {
      padding-bottom: 18px;
    }

    &:has(.doubleRow) {
      padding-bottom: 0;
    }

    .placeholder {
      height: 100%;
      width: 60%;
      margin: 5% 0;
    }

    .valueContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;

      .icon {
        height: 38px;
        color: $tertiary-petrol;
      }

      .value {
        font-family: $h1-font-family;
        font-size: $h1-font-size;
        line-height: $h1-line-height;
        font-weight: $font-weight-bold;
      }
    }

    .bottomContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .label {
        font-family: $copy-s-font-family;
        font-size: $copy-s-font-size;
        line-height: $copy-s-line-height;
        font-weight: $font-weight-bold;
      }
    }

    .subValue {
      text-align: center;
      font-family: $copy-s-font-family;
      font-size: $copy-s-font-size;
      line-height: $copy-s-line-height;
    }
  }
}
