@import '../../../../styles/_variables';

.container {
  height: 100%;
  width: 100%;
  padding: 0 25px 10px 25px;
  display: flex;
  flex-direction: column;

  .chartsContainer {
    height: 77%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .jurorChart {
      height: 100%;
      width: 57%;
    }

    .groupChart {
      height: 100%;
      width: 38%;
    }

    &.finalJury {
      justify-content: center;
    }
  }

  .labelContainer {
    height: 23%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.finalJury {
      justify-content: center;
    }

    .jurorLabels {
      width: 57%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 40px 0 0;
      margin: 0;
      list-style: none;

      &:has(.label:only-child) {
        grid-template-columns: 1fr;
      }

      .label {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .imageContainer {
          height: 40px;
          width: 40px;
          margin-bottom: 5px;

          .image {
            max-height: 100%;
            max-width: 100%;
            border-radius: 20px;
          }

          .iconContainer {
            height: 100%;
            width: 100%;
            background-color: #d8d8d8;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              color: $secondary-darkgrey;
            }
          }
        }

        .name,
        .role {
          font-family: $copy-xs-font-family;
          font-size: $copy-xs-font-size;
          line-height: $copy-xs-line-height;
        }

        .name {
          color: $primary-black;
        }

        .role {
          color: $secondary-darkgrey;
        }
      }
    }

    .groupLabels {
      width: 38%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-right: 40px;

      .groupLabel {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .imageContainer {
          height: 40px;
          width: 40px;
          margin-bottom: 5px;

          .image {
            max-height: 100%;
            max-width: 100%;
            border-radius: 20px;
          }

          .iconContainer {
            height: 100%;
            width: 100%;
            background-color: #d8d8d8;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              color: $secondary-darkgrey;
            }
          }
        }

        .name {
          text-align: center;
          font-family: $copy-xs-font-family;
          font-size: $copy-xs-font-size;
          line-height: $copy-xs-line-height;
          color: $primary-black;
        }
      }
    }
  }
}
