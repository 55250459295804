@import '../../../styles/_variables';

.averageScoreContainer {
  padding-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;
  }
  .averageScore {
    display: flex;
    flex-direction: column;
    padding: 10px;

    &:not([role='button']) {
      pointer-events: none;
    }

    &.priorSession {
      opacity: 0.6;
    }

    &.scoreAlert {
      background-color: #fddddf;

      .alertIcon {
        margin-left: 5px;
        color: $primary-red;
      }
    }

    .title {
      font-family: $copy-s-font-family;
      font-size: $copy-font-size;
      line-height: $copy-xs-line-height;
      color: $secondary-darkgrey;
    }

    .value {
      font-family: $h2-font-family;
      font-size: $h2-font-size;
      line-height: $h2-line-height;
      font-weight: $font-weight-bold;
      display: flex;
      align-items: center;

      .chairpersonLabel {
        font-size: 16px;
        max-width: 78px;
      }
    }

    .sustainabilityScore {
      font-size: 12px;
      color: $secondary-darkgrey;

      .sustainabilityScoreValue {
        color: $primary-black;
      }
    }
  }

  .arrow {
    color: $primary-red;
    margin-left: 15px;
    cursor: pointer;

    &.blue {
      color: $primary-blue;
    }
  }
}
