@import '../../../styles/_variables';

.container {
  height: 100%;

  .loginError {
    position: absolute;
    width: 100%;
    border-radius: 0;
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .loginHeadline {
      margin-bottom: 40px;
    }

    .loginForm {
      .loginButtonContainer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
