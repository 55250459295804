@import '../../../../styles/_variables';

.notAvailableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  > h2 {
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

.toolbar {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;

  .filterPills {
    margin-right: auto;
    display: flex;
  }

  .total {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $font-weight-bold;
  }
}
