@import '../../styles/_variables';

.checkbox {
  .input[type='checkbox'] {
    border-radius: 0;
    border-color: $primary-blue;
    &:focus {
      box-shadow: none;
    }
    &:checked {
      background-color: $primary-blue;
    }
  }

  .label {
    font-family: $copy-font-family;
    font-size: $copy-font-size;
    line-height: $copy-line-height;
    font-weight: $font-weight-regular;
  }
}

.icon {
  margin-left: 8px;
}

.tooltip {
  z-index: 10000 !important;

  & [class$='inner'] {
    background-color: $primary-blue;
  }
}
