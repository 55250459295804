@import '../../styles/_variables';

.dropdown {
  .sortDropdown {
    .button {
      background-color: transparent;
      border-radius: 0;
      color: $primary-black;

      &:focus {
        box-shadow: none;
      }

      &[aria-expanded='true'],
      &:active {
        background-color: transparent;
        color: $primary-black;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .label {
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      line-height: $copy-line-height;
      padding-right: 5px;
    }

    .button {
      height: 40px;
      width: 40px;
      background-color: transparent;
      border: none;

      .icon {
        height: 18px;
      }
    }

    .dropdownContainer {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.36);
      border-radius: 0;
      margin-top: 0;
      min-width: 200px;

      .item {
        padding: 4px 25px;
        position: relative;

        &:hover {
          background-color: $secondary-grey;
        }

        &:active,
        &.active,
        &[aria-selected='true'] {
          background-color: $primary-blue;
          color: $primary-white;
        }
        &:disabled,
        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        &:hover .childDropdownContainer {
          display: block;
        }

        .childDropdownContainer {
          display: none;
          position: absolute;
          right: 100%;
          top: 0;
          background-color: $primary-white;
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.36);

          .item {
            &:active,
            &[aria-selected='true'] {
              background-color: $primary-blue;
            }
          }
        }

        .itemContent {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .sortIcon {
            padding-left: 14px;
          }

          .icon {
            height: 14px;
          }
        }
      }
    }
  }
}
