.chat {
  .dropdownContainer {
    display: flex;
    flex-direction: row;
    .dropdown {
      width: 300px;
    }
    gap: 10px;
  }
}
