@import '../../../../styles/_variables';

.entry {
  .loadingContainer {
    height: 50vh;
    display: flex;
    align-items: center;
  }
  .goldAwardedContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    border: 3px solid $tertiary-gold;
    color: $tertiary-gold;

    h1 {
      font-size: $h1-font-size;
      font-family: $h3-font-family;
      line-height: $h3-line-height;
      font-weight: $font-weight-bold;
      margin: 0;
    }

    .juryStatementButton {
      background: transparent;
      border-color: $tertiary-gold;
      color: $tertiary-gold;
      border-width: 2px;
      margin-top: 2rem;
    }
  }
  .juryStatementWrapper {
    background-color: $secondary-lightblue;

    .juryStatement {
      margin: 25px 0;
      display: flex;
      flex-direction: row;
      align-items: baseline;

      > h3 {
        font-family: $h3-font-family;
        font-size: $h3-font-size;
        line-height: $h3-line-height;
        font-weight: $font-weight-bold;
        min-width: 150px;
        width: 20%;
        margin: 0;
      }
      > textarea {
        font-family: $copy-font-family;
        font-size: $h2-font-size;
        line-height: $copy-line-height;
        max-width: 700px;
        width: 100%;
        margin: 0;
        background: rgba(255, 255, 255, 0.3);
        border: 15px solid transparent;
        overflow-y: auto;

        &:focus {
          outline: none;
        }
      }

      .statusContainer {
        width: 60px;
        padding: 0 10px;
        display: flex;
        justify-content: center;

        > svg {
          height: 32px;
          width: 32px;
          color: $tertiary-petrol;
        }

        > div {
          opacity: 0.7;
        }
      }
    }
  }

  .contentWrapper {
    background-color: $secondary-lightbeige;
  }
}
