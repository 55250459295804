.sessionActions {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .heading {
    margin-bottom: 0;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
