@import '../../../styles/_variables';

.meetingsContainer {
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  height: 322px;
  display: flex;
  flex-direction: column;

  .dialogListContainer {
    overflow: auto;
    max-height: calc(70vh - 100px);
    .meetingsList {
      li {
        height: 100px;
      }
    }
  }

  .dialogFooterContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .buttonContainer {
    height: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $secondary-lightgrey;

    &:hover {
      cursor: pointer;

      .button {
        font-weight: $font-weight-bold;
      }
    }
  }

  & > div:last-child > div > div > div > div:last-child > div:last-child {
    padding: 10px 0 20px 0;
  }
}
