@import '../../../styles/_variables';

.buttonContainer {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;

  .icon,
  .commentIcon,
  .suggestForNominationIcon,
  .nominatedIcon {
    margin-right: 25px;
    transition: all 0.2s ease-in-out;

    &.active {
      color: $primary-red;
    }

    &.readOnly {
      pointer-events: none;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }

    &:active {
      transform: scale(0.8);
    }

    &.discussed {
      color: $secondary-blue;
    }
  }

  .publishRestriction {
    margin-right: 15px;
    font-weight: $font-weight-bold;
    font-size: $copy-l-font-size;
    color: $primary-blue;
    text-align: right;
  }

  .nominatedWrapper {
    display: flex;
    text-align: right;
    align-items: center;
  }
  .suggestForNominationIcon {
    &.active {
      color: $primary-blue;
    }

    &.publishRestricted {
      opacity: 0.6;
    }
  }
  .nominatedIcon {
    filter: grayscale(1);
    &.active {
      color: $tertiary-gold;
      filter: grayscale(0);
    }
  }

  .commentContainer {
    position: relative;

    .badge {
      position: absolute;
      background: $primary-red;
      height: 2rem;
      top: -1.5rem;
      right: 0.5rem;
      width: 2rem;
      text-align: center;
      line-height: 2rem;
      font-size: 1rem;
      border-radius: 50%;
      color: white;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
      }

      &.discussed {
        background-color: $secondary-blue;
      }
    }
  }
}

.charsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $secondary-grey;
}

.modalFooter {
  display: flex;
  margin-left: auto !important;
  gap: 20px;
}

.commentListWrapper {
  margin-bottom: 3em;
  .commentWrapper {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 0.25rem;
    background-color: $secondary-lightgrey;
    .name {
      font-size: 12px;
      opacity: 0.7;
    }
  }
}
