@import '../../styles/_variables';

.pill {
  background-color: $primary-blue;
  padding: 8px 10px;
  color: $primary-white;
  margin-right: 10px;

  .text::first-letter {
    text-transform: uppercase;
  }

  .icon {
    margin-right: 10px;
  }
}
