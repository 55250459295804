@import '../../styles/_variables';

.dialogFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -20px !important;
}

.meetingsTable {
  margin-bottom: 30px;

  .sortIcon {
    margin-left: 8px;
  }

  .status {
    text-transform: capitalize;
    &.active {
      color: $tertiary-petrol;
    }

    &.closed {
      color: $secondary-grey;
    }
  }

  .actionsCell {
    padding-right: 5px;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 25px;

      .action {
        height: 18px;
        width: 18px;

        &:hover {
          cursor: pointer;
          color: $primary-red;
        }
      }
    }
  }

  .expandedRow {
    background-color: $secondary-lightblue !important;
    border-bottom: 2px solid #b5b5b5 !important;

    .deleteContainer {
      padding: 33px 40px 50px;

      .text {
        font-family: $h3-font-family;
        font-size: $h3-font-size;
        line-height: $h3-line-height;
      }

      .button {
        margin: 30px auto 0px auto;
      }
    }

    .editContainer {
      padding: 33px 40px 50px;
    }
  }

  .expandedRowParent {
    &.expandedDelete {
      .delete {
        background-color: $primary-blue;
        color: $primary-white;
      }
    }
    &.expandedEdit {
      .edit {
        background-color: $primary-blue;
        color: $primary-white;
      }
    }
  }
}
