@import '../../../../styles/_variables';

.companyWrapper {
  background-color: $secondary-lightgrey;
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $primary-black;
    text-decoration: none;
  }

  .img {
    width: 25%;
  }

  .nameWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }

  .companyName {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-weight: $font-weight-bold;
  }

  .icon {
    color: $primary-red;
  }
}
