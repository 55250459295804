@import '../../../../styles/_variables';

.sessionsOverview {
  .topButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
