@import '_variables';
@import '~bootstrap/scss/bootstrap';
@import '_table';
@import '_datepicker';

@font-face {
  font-family: 'SuisseIntl';
  src:
    url('../assets/fonts/Suisse_Family/SuisseIntl-Light.woff2') format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SuisseIntl';
  src:
    url('../assets/fonts/Suisse_Family/SuisseIntl-Regular.woff2')
      format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Regular.woff') format('woff'),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SuisseIntl';
  src:
    local(SuisseIntl-Book),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Book.woff2') format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SuisseIntl';
  src:
    local(SuisseIntl-Bold),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Bold.woff2') format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseIntl-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SuisseIntlCond';
  src:
    url('../assets/fonts/Suisse_Family/SuisseIntlCond-Bold.woff2')
      format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseIntlCond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SuisseIntlCond';
  src:
    url('../assets/fonts/Suisse_Family/SuisseIntlCond-ThinItalic.woff2')
      format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseIntlCond-ThinItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SuisseWorks';
  src:
    url('../assets/fonts/Suisse_Family/SuisseWorks-Book.woff2') format('woff2'),
    url('../assets/fonts/Suisse_Family/SuisseWorks-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

.dropdown-menu {
  padding: 0;
  border: none;
  margin-top: 5px;
}

.dropdown-toggle {
  &:after {
    display: none;
  }
}
html {
  overflow-y: scroll;
}

html,
body {
  height: 100%;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
}

.carousel {
  .carousel-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: unset;
    top: calc(50% - 25px);
    bottom: unset;

    .carousel-control-prev-icon {
      background-image: url('../../public/bootstrap-carousel-prev-icon.svg');
    }
    .carousel-control-next-icon {
      background-image: url('../../public/bootstrap-carousel-next-icon.svg');
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-size: 50%;
      width: 50px;
      height: 50px;
      background-color: rgba($primary-white, 0.7);
      padding: 15px;
    }
  }
  .carousel-control-prev {
    left: 8px;
  }
  .carousel-control-next {
    right: 8px;
  }
  .carousel-indicators {
    button {
      height: 10px;
      width: 10px;
      border: solid 1px $primary-blue;
      border-radius: 50%;
      background-color: transparent;

      &.active {
        background-color: $primary-blue;
      }
    }
    &[data-bs-target] {
    }
  }
}

.react-bootstrap-table {
  .row-expand-slide-exit-active {
    transition: max-height 0s linear !important;
  }
}

a {
  text-decoration: none;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url('../../public/bootstrap-checkbox-icon.svg')
}