@import '../../styles/_variables';

.filter {
  background-color: $secondary-lightblue;
  padding: 40px 40px 90px;

  .sliderCol {
    align-self: flex-start;
    margin-top: -10px;
    position: relative;

    .thresholdContainer {
      display: flex;
      position: absolute;
      right: 0;
      font-size: 14px;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
    padding-top: 10px;
  }

  .arrowRight {
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid $primary-blue;
  }

  .val {
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    display: block;
    text-align: center;
  }

  .input {
    background-color: $primary-white;
    font-size: $h2-font-size;
    font-weight: $font-weight-bold;
    width: 165px;
  }

  .slider {
    width: 350px;
    -webkit-appearance: none;
    height: 35px;
    border-radius: 18px;
    background-color: $primary-white;
    outline: none;
    overflow: hidden;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: $primary-blue;
      box-shadow:
        -517px 0 0 500px $secondary-lightgrey,
        500px 0 0 483px rgba(90, 105, 123, 0.6);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 35px;
      height: 35px;
      border: none;
      border-radius: 50%;
      background: $primary-blue;
      box-shadow:
        -517px 0 0 500px $secondary-lightgrey,
        500px 0 0 483px rgba(90, 105, 123, 0.6);
      cursor: pointer;
    }
  }
}

.title {
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;
}

.resultWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
}

.entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  margin-bottom: 10px;
}
