@import '../../../../styles/_variables';

.charsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $secondary-grey;

  &.invalid {
    color: $primary-red;
  }
}

.textarea {
  &:disabled {
    opacity: 0.6;
  }
}

.confirmationContainer {
  cursor: auto;
  padding-top: 20px;
  padding-bottom: 45px;

  .message {
    text-align: center;
    padding: 15px 10px;
    font-family: $copy-l-font-family;
    font-size: $copy-l-font-size;
    line-height: $copy-l-line-height;
  }

  .buttonRow {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 20px;
  }
}

.modalFooter {
  display: flex;
  margin-left: auto !important;
  gap: 20px;

  &.spaceBetween {
    width: 100%;
    justify-content: space-between;
  }

  .rightButtonGroup {
    display: flex;
    gap: 20px;
  }
}

.commentListWrapper {
  margin-bottom: 3em;

  &.discussed {
    opacity: 0.6;
  }

  .commentWrapper {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 0.25rem;
    background-color: $secondary-lightgrey;
    .name {
      font-size: 12px;
      opacity: 0.7;
    }
  }
}

.discussionContainer {
  margin-top: -1.5em;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: flex-end;

  .discussionMessage {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 5px;
  }
}
