@import '../../styles/_variables';

.groupForm {
  margin-top: -20px;

  .deleteJurorColumn {
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    padding-left: 7px;
  }

  .deleteButton,
  .addButton {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-color: transparent;

    .icon {
      height: 24px;
      width: 24px;
      color: $dark-blue;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .addJurorRow {
    margin-top: 15px;
    margin-bottom: 10px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 47px;
      padding: 0;

      .maxJurorsMessage {
        font-family: $copy-font-family;
        font-size: $copy-font-size;
        line-height: $copy-line-height;
      }
    }
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
}
