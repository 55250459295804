.table {
  font-size: $copy-font-size;
  font-family: $copy-font-family;
  line-height: $copy-line-height;
  border: transparent;

  > :not(:first-child) {
    border-top: 2px solid $primary-blue;
  }

  th {
    font-weight: bold;
    color: $primary-blue;
  }
  tr {
    border-width: 0 !important;

    td {
      font-weight: normal;
      padding: 0.75rem 0.5rem;

      &:not(.react-bs-table-no-data) {
        border-bottom: 1px solid $secondary-grey;
      }

      &.reset-expansion-style {
        border-bottom: none;
        .row-expand-slide-appear-active {
          transition: none !important;
        }
        .row-explan-slide-exit-active {
          transition: none !important;
        }
        .row-expansion-style {
          padding: 0;
        }
      }
    }
  }
}
