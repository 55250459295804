@import '../../styles/_variables';

.inputField {
  cursor: pointer;
}

.footer {
  display: block;
  .button {
    margin-left: auto;
    margin-right: 20px;
  }
}

.wrapper {
  width: 100%;
}

.calendar {
  border-radius: 0;
  padding-bottom: 20px;
  border: none;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
}

.day {
  border-radius: 0;
  background-color: transparent;
  color: black;
  &:hover {
    background-color: #f0f0f0;
    border-radius: 0;
  }
  &.active {
    color: white;
    background-color: red;
  }
  &.outside {
    color: $secondary-grey;
    pointer-events: none;
  }
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-top: 8px;
  margin-bottom: 5px;
  .button {
    position: relative;
    background-color: transparent;
    border: none;
    color: white;
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $font-weight-regular;
  }
  .label {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: 300;
    color: white;
  }
}
