@import '../../../../styles/_variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    .title {
      font-family: $h2-font-family;
      font-size: $h2-font-size;
      line-height: $h2-line-height;
      font-weight: $font-weight-bold;
    }

    .instructions {
      font-family: $copy-l-font-family;
      font-size: $copy-l-font-size;
      line-height: $copy-l-line-height;
    }
  }

  .scannerContainer {
    position: relative;

    .scanner {
      width: 500px;
      height: 500px;
      border-radius: 20px;
      object-fit: cover;

      &.active {
        border: 4px solid $primary-red;
      }
    }

    .accessText {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: $copy-l-font-family;
      font-size: $copy-l-font-size;
      line-height: $copy-l-line-height;
    }

    .loadingContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px;
      height: 500px;
    }

    .errorMessage {
      font-family: $copy-l-font-family;
      font-size: $copy-l-font-size;
      line-height: $copy-l-line-height;
    }
  }
}
