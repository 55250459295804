@import '../../../styles/_variables';

.fullScreenImage {
  position: absolute;
  width: 100%;
  height: calc(100vh - $navigation-height);
  top: $navigation-height;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba($primary-blue, 0.9);
  overflow: hidden;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999;

  .dialogWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background-color: transparent;
    border: none;
  }
  .dialog {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: unset;
    margin: 0;
    position: relative;

    .prev,
    .next {
      position: absolute;
      cursor: pointer;
      opacity: 0.8;
      width: 150px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .closeIcon {
        width: 32px;
        height: 32px;
        color: $primary-white;
      }

      &.prev {
        left: -150px;
      }

      &.next {
        right: -150px;
      }
    }
    .closeButton {
      right: 10px;
      top: -40px;
      position: absolute;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
      .closeIcon {
        width: 32px;
        height: 32px;
        color: $primary-white;
      }
    }

    .imageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;

      .imageElement {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
