@import '../../../styles/_variables';

.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    .imageContainer {
      width: 75px;
      height: 75px;
      position: relative;

      .image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        &.logo {
          opacity: 1;
          visibility: visible;
          transition: all 1s ease-in-out;

          &:has(+ .profilePicture) {
            border-radius: 50%;
          }
        }

        @keyframes fade-in-image {
          0% {
            opacity: 0;
            border-radius: 0;
          }
          100% {
            opacity: 1;
            border-radius: 50%;
          }
        }

        &.profilePicture {
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.25);
          animation: fade-in-image 1s ease-in-out forwards;
          width: 100%;
          height: 100%;
          object-fit: cover; /*magic*/
        }
      }
    }

    @keyframes fade-in-greetings {
      0% {
        opacity: 0;
        visibility: hidden;
        padding-left: 0;
        max-width: 0;
      }
      100% {
        opacity: 1;
        visibility: visible;
        padding-left: 20px;
        max-width: 300px;
      }
    }

    .greetingsContainer {
      position: relative;
      overflow: visible;
      white-space: nowrap;
      animation: fade-in-greetings 1s ease-in-out forwards;

      span {
        font-family: $h2-font-family;
        font-size: $h2-font-size;
        line-height: $h2-line-height;
      }
    }
  }

  .bottomContainer {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.loginContainer {
      height: 150px;
      flex-direction: column;
    }

    .inputElements {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &:has(> .check) {
      width: 75px;
    }

    @keyframes draw-checkmark {
      0% {
        height: 0;
        width: 0;
        opacity: 1;
      }

      20% {
        height: 0;
        width: 24px;
        opacity: 1;
      }

      40% {
        height: 42px;
        width: 24px;
        opacity: 1;
      }

      100% {
        height: 42px;
        width: 24px;
        opacity: 1;
      }
    }

    .check {
      top: 50%;
      left: 20px;
      width: 24px;
      content: '';
      height: 42px;
      position: absolute;
      transform-origin: left top;
      animation: draw-checkmark 1.5s ease-in-out forwards;
      border-top: 4px solid $primary-red;
      border-right: 4px solid $primary-red;
      transform: scaleX(-1) rotate(135deg);
    }
  }
}
