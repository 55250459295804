@import '../../styles/_variables';

.contactBlock {
  .name {
    font-size: $copy-l-font-size;
    font-weight: bold;
    line-height: $copy-l-line-height;
  }
  .title {
    font-weight: bold;
  }
  .icon {
    margin-right: 10px;
  }
}
