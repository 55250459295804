@import '../../../styles/_variables';

.nameColumnContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  position: relative;
  padding: 35px 0;

  .bookmarkButton {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    background: transparent;

    &.active .icon {
      color: $primary-red;
    }

    .icon {
      color: $secondary-grey;
      width: 18px;
      height: 20px;
    }
  }

  .leftSection {
    width: 164px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;

    .image {
      width: inherit;
      height: 100%;
      max-height: 170px;
      object-fit: cover;
      cursor: pointer;
      aspect-ratio: 4/3;

      &.disabled {
        cursor: auto;
      }

      &.empty {
        width: 100%;
        min-width: 164px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $secondary-lightgrey;
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
      background-color: $primary-white;

      .iconContainer {
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s ease-out;
        cursor: pointer;

        .icon {
          height: 18px;
        }

        &:hover {
          background-color: rgba($secondary-lightblue, 0.7);
        }

        &.active {
          background-color: $secondary-lightblue;
          &:hover {
            background-color: rgba($secondary-lightblue, 0.7);
          }
        }

        &.disabled {
          background-color: $primary-white;
          color: $secondary-grey;
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .nameContainer,
    .categoryContainer {
      display: flex;
      flex-direction: column;
    }

    .idContainer {
      .title {
        padding-right: 8px;
      }
    }

    .title {
      text-transform: uppercase;
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      line-height: $copy-line-height;
    }

    .value {
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      line-height: $copy-line-height;
      font-weight: $font-weight-bold;
    }

    .entryName {
      font-family: $h2-font-family;
      font-size: $h2-font-size;
      line-height: $h2-line-height;
      font-weight: $font-weight-bold;
    }

    .disciplineName {
      font-family: $h2-font-family;
      font-size: $h2-font-size;
      line-height: $h2-line-height;
      font-weight: $font-weight-light;
    }
  }
}
