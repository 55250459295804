@import '../../styles/_variables';

.awardStatistic {
  background-color: $secondary-lightgrey-alt;

  .container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

    .loadingSpinner {
      position: absolute;
      top: 40px;
      right: 0;
      opacity: 0.7;
    }

    .statisticItemsContainer {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      justify-content: space-between;
      align-items: center;
      margin-top: 45px;

      &.finalJury {
        grid-template-columns: repeat(7, 1fr);

        .column {
          padding-bottom: 20px;
        }
      }

      .column {
        height: 145px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 10px;

        .placeholder {
          height: 100%;
          width: 60%;
          margin: 5% 0;
        }

        .valueContainer {
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: center;
          align-items: center;

          .icon {
            height: 38px;
            color: $tertiary-petrol;
          }

          .value {
            font-family: $h1-font-family;
            font-size: $h1-font-size;
            line-height: $h1-line-height;
            font-weight: $font-weight-bold;
          }
        }

        .label {
          font-family: $copy-s-font-family;
          font-size: $copy-s-font-size;
          line-height: $copy-s-line-height;
          font-weight: $font-weight-bold;
        }

        &:has(.subValue) {
          padding-bottom: 0;
        }

        .subValue {
          height: 20px;
          font-family: $copy-s-font-family;
          font-size: $copy-s-font-size;
          line-height: $copy-s-line-height;
        }
      }
    }
  }
}
