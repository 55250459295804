@import '../../../../styles/_variables';

.accordionItem {
  .header {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    width: 100%;
    color: $primary-black;
    &.isOpen {
      .headerContent {
        border-bottom: none;
      }
    }

    .headerContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px;
      cursor: pointer;
      &.isFirst {
        border-top: 2px solid $secondary-grey;
      }

      width: 100%;
    }
    .leftAligned {
      text-align: left;
    }
    .icon {
      color: $secondary-darkgrey;
      align-self: center;
      margin-right: 15px;
    }
    .arrow {
      margin-left: 15px;
      color: $primary-red;
      width: 30px;
      height: auto;
    }
  }
  .contentContainer {
    border-bottom: 2px solid $secondary-grey;

    .content {
      background-color: $secondary-lightbeige;
      display: inline-block;
      padding: 30px 30px 50px 30px;
      width: 100%;
      img[alt~='markdownIcon'] {
        width: 18px;
        height: 18px;
        transform: translateY(-2px);
      }
    }

    .mediaBlock {
      margin-top: 30px;
      .mediaList {
        margin-top: 20px;
        display: flex;
        .media {
          max-width: 258px;
          display: flex;
          flex-direction: column;
          margin-right: 24px;
          > span {
            font-size: 14px;
          }
          .playIconContainer {
            align-items: center;
            background-color: rgba($primary-white, 0.8);
            border-radius: 100%;
            display: flex;
            height: 60px;
            justify-content: center;
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translate(50%, -50%);
            width: 60px;
            .play {
              color: $primary-red;
            }
          }
        }
      }
    }

    .fileBlock {
      margin-top: 30px;
      .file {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        > a {
          width: min-content;
        }
        .downloadButton {
          margin-top: 15px;
          min-width: 180px;
          > svg {
            margin-left: auto;
          }
        }
      }
    }
  }
}
