@import '../../styles/_variables';

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1337;
  background-color: $primary-white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    width: 50px;
    height: 50px;
    animation: logoLoading 1.5s infinite ease-in-out;
  }
}

@keyframes logoLoading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}