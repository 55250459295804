@import '../../../styles/_variables';
@import '~bootstrap/scss/bootstrap';

.entryContainer {
  padding: 30px 20px;

  .entryInformation {
    display: flex;
    justify-content: space-between;

    .entryId {
      font-size: $copy-font-size;
      font-weight: 300;
      color: $primary-black;
      text-transform: uppercase;

      .bold {
        font-weight: $font-weight-bold;
      }
    }

    .entryInformationLeft {
      display: flex;
      flex-direction: column;

      .title {
        color: $primary-black;
        font-size: $h1-font-size;
        font-family: $h1-font-family;
        font-weight: 700;
        margin-top: 20px;
      }

      .subtitle {
        color: $primary-black;
        font-size: $h1-font-size;
        font-family: $h2-font-family;
        font-weight: 300;
      }
    }
  }
}

.bookmark {
  display: block;
  margin-top: 30px;
  margin-left: auto;
}

.entryMain {
  .entryCarousel {
    width: 100%;
    padding-top: 56.25%;

    .carouselSlide {
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &.empty {
        background-color: $secondary-lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .fullScreenBtn {
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: rgba($primary-white, 0.6);
      padding: 10px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .iconFullScreen {
      cursor: pointer;
      width: 32px;
      height: 24px;
    }
  }

  .entryDescription {
    margin-left: -15px;
    padding: 0 45px 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .entryDescriptionInner {
      font-size: $h3-font-size;
      color: $primary-black;
      line-height: $copy-l-line-height;
      font-weight: 500;
      padding-top: 1em;
      @include media-breakpoint-up(lg) {
        padding-top: 0;
      }
    }

    .sizingIconWrapper {
      flex-grow: 0;
      margin-top: 5px;
    }

    .detailValue {
      font-size: $copy-font-size;
      display: block;

      .bold {
        font-weight: $font-weight-bold;
      }
    }

    .detailCompany {
      font-size: $copy-font-size;
      font-weight: $font-weight-bold;
      margin-top: 20px;
      display: block;
    }
  }
}

.informationRow {
  padding: 20px 0;

  .informationButtons {
    width: 100%;
  }
  .protectedUrl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: auto;

    .protectedLabel {
      display: flex;
      align-items: baseline;
      font-size: $copy-font-size;
      color: $primary-black;
      gap: 10px;

      .label {
        font-size: $copy-xs-font-size;
        color: $secondary-darkgrey;
        text-transform: uppercase;
      }

      .value {
        white-space: nowrap;
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;
  position: fixed;

  & [class$='arrow'] {
    left: -11px !important;

    &:before {
      border-width: 18px 18px 0 18px !important;
      border-top-color: $secondary-blue !important;
    }
  }

  & [class$='inner'] {
    border-radius: 0;
    background-color: $secondary-blue;
    max-width: 420px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
