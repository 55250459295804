@import '../../../styles/_variables';

.logout {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}
