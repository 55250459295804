@import '../../../styles/_variables';

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.hintContainer {
  padding: 0 15px;
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;

  .hint {
    display: block;
    color: $secondary-darkgrey;
    font-family: $copy-xs-font-family;
    font-size: $copy-xs-font-size;
    line-height: $copy-xs-line-height;
  }
}

.buttonRow {
  padding-top: 30px;
  padding-bottom: 30px;

  &.editButtons {
    .button {
      margin-left: auto;
    }
  }

  &.addButtons {
    .addButton {
      margin-left: auto;
    }
  }
}

.uploadButtonRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.filesContainer,
.linksContainer {
  margin-bottom: 30px;

  & > div {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .file,
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    color: $primary-black;
    cursor: pointer;

    &:hover {
      background-color: $secondary-lightgrey;
    }

    .details {
      .title {
        display: inline-block;
        font-family: $copy-font-family;
        font-size: $copy-font-size;
        line-height: $copy-line-height;
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
      }

      .metadataContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        .icon {
          font-size: 32px;
          color: $secondary-grey;
        }

        .filename,
        .linkUrl {
          margin: auto 0;
          font-family: $copy-font-family;
          font-size: $copy-font-size;
          line-height: $copy-line-height;
        }
      }
    }

    .deleteIcon {
      padding-right: 15px;
      color: $primary-blue;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        color: $primary-red;
      }
    }
  }
}
