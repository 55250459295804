@import '../../../styles/_variables';

.entries {
  .heroRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .text {
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      line-height: $copy-line-height;
    }

    .icon {
      height: 18px;
    }
  }

  .toolbar {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    .resetFilter {
      border: 0;
      background-color: transparent;
      text-decoration: underline;
      opacity: .6;
      transition: opacity ease-in-out .1s;
      &:disabled {
        &:hover {
          opacity: .6;
          pointer-events: none;
        }
      }
      &:hover {
        opacity: 1;
      }
    }

    .filterPills {
      margin-right: auto;
      display: flex;
    }

    .total {
      font-family: $h3-font-family;
      font-size: $h3-font-size;
      line-height: $h3-line-height;
      font-weight: $font-weight-bold;
    }
  }

  .tableContainer {
    margin: 20px 0px;
  }
}
