@import '../../../../styles/_variables';

.dropdown {
  .dropdownButton {
    width: fit-content;
    padding: 0;
    background-color: transparent;
    border: none;

    span {
      font-family: $h2-font-family;
      font-size: $h2-font-size;
      line-height: $h2-line-height;
    }

    .icon {
      padding-left: 5px;
      padding-bottom: 2px;
    }
  }

  .optionsContainer {
    position: absolute;
    margin-top: 5px;
    width: 180px;
    display: none;
    flex-direction: column;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.36);
    background-color: $primary-white;
    overflow: auto;

    &.active {
      display: flex;
      z-index: 2;
    }

    .list {
      margin: 0;
      padding: 0;
      list-style: none;

      .listItem {
        height: 48px;

        &.active,
        &:hover {
          background-color: $secondary-lightblue;
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          width: 100%;
          height: 100%;
          padding: 0 10px;
          background-color: transparent;
          border: none;

          .iconContainer {
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: #d8d8d8;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: 20px;
              height: 16px;
              color: $secondary-darkgrey;
            }
          }

          .label {
            font-family: $copy-s-font-family;
            font-size: $copy-s-font-size;
            line-height: $copy-s-line-height;
          }
        }
      }
    }
  }
}
