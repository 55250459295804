@import '../../../../styles/_variables';

.evaluationCriterion {
  cursor: pointer;
  overflow: hidden;
  border-bottom: 1px solid $secondary-lightgrey;
  background-color: $primary-white;
  transition: background-color 0.5s ease-in-out;

  &:first-child {
    border-top: 1px solid $secondary-lightgrey;
  }

  .leftSection {
    padding-top: 33px;
    padding-bottom: 33px;

    .header {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;

      .icon {
        height: 18px;
        color: $primary-red;
        transition: all 0.5s ease-in-out;

        &.rotated {
          transform: rotate(-180deg);
        }
      }

      .criterionTitle {
        margin: 0;
        font-family: $h3-font-family;
        font-size: $h3-font-size;
        line-height: $h3-line-height;
        font-weight: $font-weight-bold;
      }
    }

    .description {
      visibility: hidden;
      opacity: 0;
      overflow: auto;
      transition: visibility 0s, opacity 0.5s ease-in-out;

      &.visible {
        visibility: visible;
        opacity: 1;
      }

      p {
        font-family: $copy-l-font-family;
        font-size: $copy-l-font-size;
        line-height: $copy-l-line-height;
        font-weight: $font-weight-regular;
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: row;
    align-items: center;

    .sliderContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;
      position: relative;
      margin-left: 70px;
      padding-right: 10px;

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 36px;
        border-radius: 18px;
        border: 1px solid white;
        overflow: hidden;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 34px;
          width: 34px;
          border-radius: 17px;
          background-color: $primary-blue;
          box-shadow: -517px 0 0 500px rgba(90, 105, 123, 0.6),
            500px 0 0 483px $secondary-lightgrey;
          transition: 0.5s ease-in-out;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          height: 34px;
          width: 34px;
          border-radius: 17px;
          border: none;
          background-color: $primary-blue;
          box-shadow: -517px 0 0 500px rgba(90, 105, 123, 0.6),
            500px 0 0 483px $secondary-lightgrey;
          transition: box-shadow 0.5s ease-in-out;
          cursor: pointer;
        }

        &.expanded {
          &::-webkit-slider-thumb {
            box-shadow: -517px 0 0 500px rgba(90, 105, 123, 0.6);
          }
          &::-moz-range-thumb {
            box-shadow: -517px 0 0 500px rgba(90, 105, 123, 0.6);
          }
        }

        &.noValue {
          background-color: #efefef;
          &::-webkit-slider-thumb {
            box-shadow: none;
            background-color: #e6e6e6;
            background-image: url('../../../../assets/images/arrowLeftRight.svg');
            background-size: 32px 22px;
            background-repeat: no-repeat;
            background-position: center;
          }

          &::-moz-range-thumb {
            box-shadow: none;
            background-color: #e6e6e6;
            background-image: url('../../../../assets/images/arrowLeftRight.svg');
            background-size: 32px 22px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }

      .iconContainer {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .iconWrapper {
          height: 36px;
          width: 36px;
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .thumbIcon {
            color: $primary-white;
            width: 32px;
            height: 22px;
          }
        }
      }

      .rangeDescriptionContainer {
        position: absolute;
        width: 100px;
        top: -25px;
        text-align: center;

        .rangeDescription {
          font-family: $copy-s-font-family;
          font-size: $copy-s-font-size;
          line-height: $copy-s-line-height;
          font-weight: $font-weight-bold;
        }

        &.scoreFromPriorSession {
          top: 35px;
          color: $primary-red;
        }

        &.scoreFromPriorSessionValue {
          top: 50px;
          font-family: $copy-l-font-family;
          color: $primary-blue;
          font-size: $copy-l-font-size;
          font-weight: bold;
          opacity: 0.7;
        }
      }
    }

    .points {
      width: 15%;
      text-align: right;
      color: $primary-blue;
      font-family: $h1-font-family;
      font-size: $h1-font-size;
      line-height: $h1-line-height;
      font-weight: 600;
      display: flex;
      flex-direction: column;

      .pointsElement {
        position: relative;
        .scoreFromPriorSession {
          position: absolute;
          right: 0;
          font-size: $copy-l-font-size;
          opacity: 0.7;
          display: flex;
          justify-content: flex-end;
          label {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }

  &.expanded {
    cursor: unset;
    background-color: $secondary-lightblue;

    .leftSection {
      .header {
        cursor: pointer;
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;

  & [class$='arrow'] {
    top: -11px !important;

    &:before {
      border-width: 18px 18px 18px 0 !important;
      border-right-color: $secondary-blue !important;
    }
  }

  & [class$='inner'] {
    border-radius: 0;
    background-color: $secondary-blue;
    max-width: 420px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  svg {
    height: 32px;
    width: 32px;
    color: white;
  }

  p {
    margin: 0;
    font-family: $copy-font-family;
    font-size: $copy-font-size;
    line-height: $copy-line-height;

    ul {
      margin: 0;
      padding: 0 0 0 5px;
      text-align: left;
    }
  }
}
