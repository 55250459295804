.checkboxContainer {
  margin-top: 20px;
}

.footerButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .rightButtonGroup {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
