@import '../../../styles/_variables';

.informationList {
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);

  .listContainer {
    .list {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;

      .item {
        height: 64px;
        padding: 0 20px 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $secondary-lightgrey;
        cursor: pointer;
        color: $primary-black;
        text-decoration: none;

        &:visited {
          text-decoration: none;
          color: $primary-black;
        }
        &:last-child {
          border-bottom: none;
        }

        &:hover {
          .text {
            text-decoration: underline;
          }
        }

        .text {
          padding: 15px 0;
          font-family: $copy-font-family;
          font-size: $copy-font-size;
          line-height: $copy-line-height;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .icon {
          color: $primary-red;
          height: 24px;
          width: 24px;
        }

        .placeholder {
          height: 25px;
        }
      }
    }
  }

  .buttonContainer {
    height: 73px;
    border-top: 1px solid $secondary-lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;

    &.finalJury {
      height: 91px;
    }

    .buttonPlaceholder {
      height: 60%;
    }
  }
}
