@import '../../styles/_variables';

    .debugContainer {
        position: absolute !important;
        z-index: 1337;

    .debugToggle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .8;
        cursor: pointer;
        transition: all .1s ease-in-out;
    
        &:hover {
            opacity: 1;
        }
    
        .debugIcon {
            color: $primary-white;  
        }
    }

    .debugMenu {
        border-radius: 3px;
        box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);

        .menuHeader {
            font-weight: bold;
            color: $primary-blue;
        }

        .debugMenuAccordionBody {
            padding: 5px;
        }

        .debugMenuAccordionHeader {
            padding: 0;

            button {
                padding: 10px 15px;
                background-color: white;
                color: $primary-blue;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}