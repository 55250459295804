@import '../../styles/_variables';

.hero {
  height: 150px;
  display: flex;
  align-items: center;
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  padding-top: 29px;
  padding-bottom: 22px;

  .heroContainer {
    display: flex;
    justify-content: space-between;

    .wrapper {
      display: flex;
      align-items: center;
    }

    .heroLeft {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .headline {
        font-family: $copy-font-family;
        font-size: $copy-font-size;
        line-height: $copy-line-height;
      }

      .title {
        font-size: $h2-font-size;
        font-family: $h2-font-family;
        line-height: $h2-line-height;
        color: $primary-black;
        text-transform: uppercase;
        margin: 0;

        &.bigTitle {
          font-size: $h1-font-size;
          line-height: $h1-line-height;
        }
      }
    }

    .rightSection {
      margin-left: 60px;
    }

    .heroRight {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &.multiLine {
    height: 150px;
    .heroContainer {
      .heroLeft {
        .title {
          text-transform: none;
        }
      }
      .heroRight {
        padding-left: 12px;
      }
    }
  }
}
