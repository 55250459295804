@import '../../../styles/_variables';

.heroRight {
  width: 100%;
  display: flex;
  flex-direction: row;

  .rightSection,
  .leftSection {
    width: 50%;
    padding-right: 5px;

    .itemContainer {
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-bottom: 10px;
      }

      .title {
        color: $secondary-blue;
        font-family: $copy-xs-font-family;
        font-size: $copy-xs-font-size;
        line-height: $copy-xs-line-height;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }

      .value {
        font-family: $copy-font-family;
        font-size: $copy-font-size;
        line-height: $copy-line-height;

        .icon {
          height: 14px;
          width: 14px;
          padding: 0px 5px 0px 2px;
        }
      }
    }
  }
}
