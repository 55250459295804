@import '../../../styles/_variables';

.outerContainer {
  cursor: pointer;

  &.active {
    cursor: unset;
    pointer-events: none;

    .container {
      .titleContainer {
        cursor: pointer;
        pointer-events: all;
      }
    }
  }
}

.container {
  overflow: hidden;
  background-color: $primary-white;
  transition: background-color 0.5s ease-in-out;
  font-family: $suisse-int;
  max-height: 103px;

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-top: 33px;
    padding-bottom: 33px;

    .icon {
      height: 18px;
      color: $primary-red;
      transition: all 0.5s ease-in-out;

      &.rotated {
        transform: rotate(-180deg);
      }
    }

    .titleText {
      margin: 0;
      font-family: $h3-font-family;
      font-size: $h3-font-size;
      line-height: $h3-line-height;
    }
  }

  .questionsContainer {
    padding-bottom: 33px;

    .questionContainer {
      margin: 0;
      padding: 25px 0;

      &:first-child {
        padding-top: 10px;
      }

      .question {
        padding: 0;
        margin-bottom: 10px;

        .questionTitle {
          font-family: $h3-font-family;
          font-size: $h3-font-size;
          line-height: $h3-line-height;
          font-weight: $font-weight-bold;
        }
      }

      .answerOuterContainer {
        padding: 10px 0;

        .answerContainer {
          display: flex;
          gap: 20px;
          align-items: center;

          .checkbox {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $secondary-grey;

            &.checked {
              background-color: $primary-blue;
            }
          }

          .answerText {
            font-family: $copy-l-font-family;
            font-size: $copy-l-font-size;
            line-height: $copy-l-line-height;
          }
        }
      }
    }

    .separator {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $secondary-lightgrey;
    }
  }
}
