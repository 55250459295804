@import '../../styles/_variables';

.button {
  font-family: $copy-font-family;
  font-size: $copy-font-size;
  letter-spacing: 1px;
  margin: 0;
  border: 0;
  height: 40px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    cursor: pointer;
    font-weight: $font-weight-bold;

    .icon {
      display: none;

      &.iconBold {
        display: inline-block;
      }
    }
  }

  &:before {
    content: attr(data-label);
    font-weight: bold;
    visibility: hidden;
  }

  .label {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 15px;
    right: 15px;
  }

  &.primary,
  &.secondary,
  &.tertiary {
    &,
    &.large {
      &:disabled {
        background-color: $secondary-grey;
        border-color: $secondary-grey;
        color: $primary-white;
        &:hover {
          color: $primary-white;
          background-color: $secondary-grey;
          border-color: $secondary-grey;
          font-weight: normal;
        }
      }
    }
  }

  &.normal {
    padding: 10px 15px;
  }

  &.large {
    height: 50px;
    padding: 15px;
    font-size: $copy-l-font-size;
    letter-spacing: 1.7px;
    text-transform: uppercase;

    .label {
      top: 15px;
      bottom: 15px;
    }

    .icon {
      height: 18px;
      width: 18px;
      margin-left: 68px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 20px;

    &.iconBold {
      display: none;
    }
  }

  &.withIcon {
    text-align: left;
  }

  &.primary {
    background-color: $primary-blue;
    color: $primary-white;
    border-color: $primary-blue;

    &.large {
      &:hover {
        color: $primary-blue;
        background-color: $primary-white;
      }
    }
  }

  &.secondary {
    background-color: $primary-white;
    color: $primary-blue;
    border-color: $primary-blue;

    &.large {
      &:hover {
        color: $primary-white;
        background-color: $primary-blue;
      }
    }
  }

  &.tertiary {
    background-color: $primary-red;
    color: $primary-white;
    border-color: $primary-red;

    &.large {
      &:hover {
        color: $primary-red;
        background-color: $primary-white;
      }
    }
  }
}
