@import '../../styles/_variables';

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 15px;
}

.whiteBackground {
  background-color: $primary-white;

  &:read-only {
    background-color: $primary-white;
  }

  &:valid {
    background-color: $primary-white;
  }
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}
