.carousel {
  height: 100%;

  a {
    display: none;
  }

  button {
    width: 10px !important;
    height: 10px !important;
    border-radius: 20px;
    margin: 0 10px !important;
    background-color: transparent !important;
    border: 1px solid white !important;

    &[aria-current='true'] {
      background-color: white !important;
    }
  }

  .carouselItem {
    height: 100%;

    .carouselImage {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .iconContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
