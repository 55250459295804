@import '../../styles/_variables';

.dialogContainer {
  position: absolute;
  width: 100%;
  height: calc(100vh - $navigation-height);
  top: $navigation-height;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba($primary-blue, 0.9);
  overflow: hidden;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1337;
  opacity: 1 !important;

  &.noNav {
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9999;
  }

  &.scrollable {
    overflow: auto;
  }

  .dialogWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding: 0 30px;
  }
  .dialog {
    cursor: default;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: unset;
    margin: 0;
    position: relative;
    background-color: $primary-white;

    .closeButton {
      right: 10px;
      top: -40px;
      position: absolute;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
      .closeIcon {
        width: 32px;
        height: 32px;
        color: $primary-white;
      }
    }
    .header {
      border: none;
      padding: 30px 0 30px 0;
      justify-content: flex-start;

      .title {
        font-size: $h2-font-size;
        font-family: $h2-font-family;
        color: $primary-black;
      }

      .icon {
        margin-right: 10px;
      }
    }
    .body {
      margin: 0;
      padding: 0;
    }
    .footer {
      margin: 0;
      padding: 55px 0 45px 0;
      display: flex;
      justify-content: space-between;
      border: none;
    }
  }

  textarea {
    resize: none;
    background-color: $secondary-lightgrey;
    width: 100%;
    border: none;
    border-bottom: 2px solid $primary-blue;
    padding: 1em;
  }
}
