@import '../../../styles/_variables';

.sessionEnded {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;

  .logo {
    width: 75px;
    margin-bottom: 1.25em;
  }

  h1 {
    font-family: $h1-font-family;
    font-weight: bold;
  }

  h2 {
    font-family: $h2-font-family;
    font-weight: 500;
    font-size: 24px;
  }
}
