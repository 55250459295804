@import '../../styles/_variables';

.inputAutoFill {
  width: 100%;
  position: relative;

  .suggestionList {
    position: absolute;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: -15px;
    z-index: 2;
    overflow: auto;
    max-height: 100px;
    background-color: $primary-white;

    &.loading {
      padding: 10px 0;
      display: flex;
      align-items: center;
    }

    .item {
      padding: 5px 15px;
      cursor: pointer;
      background-color: $primary-white;

      &:hover {
        color: $primary-white;
        background-color: $primary-blue;
      }

      .itemText {
        font-family: $copy-font-family;
        font-size: $copy-font-size;
        line-height: $copy-line-height;
      }
    }
  }
}
