@import '../../styles/_variables';

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    border-bottom: 1px solid $primary-blue;
    font-weight: $font-weight-regular;
    color: $secondary-darkgrey;
    padding: 0 22px;
    font-weight: $font-weight-bold;

    &:nth-child(2) {
      color: $primary-blue;
    }
  }

  td {
    padding: 19px 22px;
    color: $secondary-darkgrey;

    &:nth-child(2) {
      font-weight: $font-weight-bold;
      color: $primary-blue;
    }
  }

  .tr {
    border-bottom: 1px solid $secondary-lightgrey;
  }

  .loading {
    min-height: 150px;
  }
}