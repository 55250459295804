@import '../../styles/_variables';

.formGroup {
  position: relative;
  margin: 15px 0;

  &:hover {
    .formControl {
      background-color: $primary-blue;
      color: $primary-white;
      &:focus,
      &:valid {
        ~ .label {
          color: $primary-white;
        }
      }
      &[type='time'] {
        &:valid {
          color: $primary-white !important;
        }
      }
    }
    .label {
      color: $primary-white;
    }
    .icon {
      color: $primary-white;
    }
  }
  .formControl {
    height: 50px;
    width: 100%;
    font-size: $copy-font-size;
    font-family: $copy-font-family;
    padding: 21px 15px 9px 15px;
    border-radius: 0;
    border: none;
    background-color: $secondary-lightgrey;
    border-bottom: 2px solid $primary-blue;
    transition: 0.2s;

    &[type='time'] {
      color: transparent;
      &:hover {
        color: transparent;
      }
      &:focus,
      &:valid {
        color: $primary-black;
        &:hover {
          color: $primary-white;
        }
      }
    }

    &:hover,
    &:read-only:hover {
      background-color: $primary-blue;
      color: $primary-white;
      ~ .icon {
        color: $primary-white;
      }
      ~ .label {
        color: $primary-white;
      }
      &:focus,
      &:valid {
        ~ .label {
          color: $primary-white !important;
        }
      }
    }
    &:focus,
    &:valid,
    &:read-only {
      outline: none;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid $primary-blue;
      ~ .label {
        font-size: $copy-xs-font-size;
        bottom: 30px;
      }
    }

    &:focus:not(:read-only) {
      ~ .label {
        color: $primary-black;
      }
    }

    &.visited {
      &:invalid {
        background-color: rgba($tertiary-pink, 0.2);
        border-color: $tertiary-pink;
        ~ .label {
          color: $primary-red;
        }
        ~ .icon {
          color: $primary-red;
        }
      }
    }
  }
  .label {
    bottom: 12px;
    left: 12px;
    position: absolute;
    background-color: transparent;
    padding: 0px 5px 0px 5px;
    color: $secondary-grey;
    font-size: $copy-font-size;
    font-family: $copy-font-family;
    transition: 0.2s;
    pointer-events: none;
  }

  .formControl:required ~ .label {
    &:before {
      content: '* ';
    }
  }

  .icon {
    bottom: 12px;
    right: 12px;
    position: absolute;
  }
}
