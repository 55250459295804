@import '../../../../styles/_variables';

.dialogContainer {
  & > div > div {
    padding: 0 80px !important;
  }

  .formWrapper {
    padding-bottom: 40px;
  }
}
