@import '../../../styles/_variables';

.statusContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.iconContainer {
  padding: 10px;
  width: 42px;
  height: 42px;
  color: $primary-white;

  &.petrol {
    background-color: $tertiary-petrol;
  }

  &.blue {
    background-color: $primary-blue;
  }

  &.grey {
    background-color: $secondary-grey;
  }

  .icon {
    height: 22px;
    width: 22px;
  }
}

.statusItem {
  border-bottom: 1px solid $secondary-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 8px 0;
  font-size: $copy-s-font-size;

  &:last-child:not(:first-child) {
    border-bottom: none;
  }

  .highlightImpersonation {
    font-weight: bold;
  }

  .rightAligned {
    display: flex;
    align-items: center;
  }

  .icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;

    &.green {
      color: $tertiary-petrol;
    }

    &.red {
      color: $tertiary-pink;
    }

    &.black {
      color: $primary-black;
    }
  }

  &.hoverable {
    &:hover {
      .link,
      .points {
        text-decoration: underline;
      }
    }
  }

  .hiddenIcon {
    height: 16px;
    width: 16px;
  }
}
