@import '../../styles/_variables';

.entriesTable {
  table {
    height: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;

    td {
      vertical-align: middle;
    }
  }

  .entriesTableRow {
    background-color: #f5f5f5;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        background-color: darken(#f5f5f5, 5%);
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  .nameColumn {
    height: inherit;
    padding: 0;
    padding-right: 1rem;
    border-bottom-width: 1px !important;
  }

  .countryColumn {
    padding-right: 15px;
    font-family: $copy-font-family;
    font-size: $copy-font-size;
    line-height: $copy-line-height;
    font-weight: $font-weight-bold;
  }

  .averageIcon {
    padding-right: 3px;
  }

  .averageScoreColumn {
    height: inherit;
    border-bottom-width: 1px !important;
  }

  .averageScoreColumnHeader {
    cursor: pointer;
  }

  .statusColumn {
    padding: 0;
    padding-right: 1rem;
    border-right: none;
    border-left: none;
    border-bottom-width: 1px !important;
  }

  .actionsColumn {
    height: inherit;
    padding: 0;
    padding-right: 1rem;
    border-bottom-width: 1px !important;
  }

  .tabletView {
    padding: 0;

    .tabletViewRow {
      display: flex;
      flex-direction: column;

      .topRowContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .bottomContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        padding-bottom: 10px;
        padding: 0 10px 30px 10px;

        .statusColumn {
          flex: 1;
          max-width: 180px;
        }
      }
    }
  }
}
