@import '../../../styles/_variables';

.container {
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  position: relative;
  display: flex;
  flex-direction: row;
  height: 244px;

  .list {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      height: 25%;
      border-bottom: 1px solid $secondary-lightgrey;

      &:last-child {
        border-bottom-color: transparent;
      }

      &.placeholder {
        display: flex;
        align-items: center;
        padding-left: 30px;

        span {
          height: 40%;
        }
      }

      .item {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          .name {
            text-decoration: underline;
          }
        }

        .profilePicture {
          padding: 0 10px;

          .profileIconContainer {
            height: 40px;
            width: 40px;
            background-color: #d8d8d8;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              color: $secondary-darkgrey;
            }
          }

          .img {
            height: 40px;
            width: 40px;
            object-fit: cover;
            border-radius: 20px;
          }
        }

        .textContainer {
          flex-grow: 1;
          padding: 0 10px;

          font-family: $copy-font-family;
          font-size: $copy-font-size;
          line-height: $copy-line-height;

          .name {
            font-weight: $font-weight-bold;
            color: $primary-black;
            padding-right: 5px;
          }

          .role {
            color: $secondary-darkgrey;
          }
        }

        .iconContainer {
          .icon {
            color: $primary-red;
            width: 21px;
            height: 24px;
          }
        }
      }
    }
  }
}
