@import '../../styles/_variables';

.actions {
  text-align: right;
  vertical-align: middle;

  .action {
    margin-right: 1rem;
    height: 18px;
    width: 18px;

    &:hover {
      cursor: pointer;
    }
  }
}

.groupsTable {
  margin-top: 20px;

  .sortCaret {
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .averageIcon {
    padding-right: 3px;
  }

  table {
    height: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;

    thead {
      th {
        padding: 8px 0;
        border-bottom: 1px solid $primary-black !important;
      }
    }

    tbody {
      tr {
        td {
          margin-bottom: 10px;
          background-color: #f5f5f5;
        }
      }
    }
  }

  td {
    padding: 15px 0 20px 0 !important;
    border-left: none;
    border-right: none;
  }

  a.multiValuedCell {
    display: block;
  }

  td.multiValuedCell {
    span,
    a {
      color: $primary-black;
      padding: 8px 0;
      text-decoration: none;
      font-size: $copy-s-font-size;

      &:first-of-type {
        padding-top: 0;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $secondary-grey;
      }
    }
  }

  .meetingsCell {
    display: flex;
    flex-direction: column;
  }

  .groupCell {
    display: flex;

    .groupImage {
      width: 164px;
      height: 130px;
      object-fit: cover;
      margin-right: 25px;
    }
  }

  .actionCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0 10px;

    .discussionMode {
      display: flex;
      flex-direction: row;
    }
  }

  .label {
    text-transform: uppercase;
    color: $secondary-grey;
    font-size: $copy-xs-font-size;
    display: block;
  }

  .bigValue {
    color: $primary-black;
    font-size: $h2-font-size;
  }
}

.dialogContainer {
  & > div > div {
    padding: 0 80px !important;
  }

  .formWrapper {
    padding-bottom: 40px;
  }
}
