@import '../../styles/_variables';

.formGroup {
  .textInput {
    cursor: pointer;
  }

  .fileInput {
    display: none;
  }
}
