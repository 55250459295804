@import '../../../styles/_variables';

.groupDropdown {
  position: relative;

  .button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $secondary-blue;
    font-family: $copy-xs-font-family;
    font-size: $copy-xs-font-size;
    line-height: $copy-xs-line-height;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    &.disabled {
      pointer-events: none;
    }

    svg {
      padding-left: 5px;
    }
  }

  .select {
    width: 90px;
    opacity: 0;
    position: absolute;
    left: 0;
    cursor: pointer;

    &.disabled {
      cursor: initial;
      pointer-events: none;
    }
  }
}
