.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  > div {
    display: flex;

    a:first-of-type {
      margin-right: 24px;
    }
  }
}
