@import '../../styles/_variables';

.dialogFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -20px !important;
}

.sessionsTable {
  margin-bottom: 50px;

  .closed {
    td {
      opacity: 0.5;

      &:last-child {
        opacity: 1;
      }
    }
  }

  .emptyMessage {
    color: $secondary-darkgrey;
  }

  .sortIcon {
    margin-left: 8px;
  }

  .phaseCell {
    .phase {
      padding-right: 15px;
    }
    .maxPoints {
      font-weight: $font-weight-bold;
    }
  }

  .status {
    text-transform: capitalize;
    &.active {
      color: $tertiary-petrol;
    }

    &.closed {
      color: $secondary-grey;
    }
  }

  .actionsCell {
    padding: 0 !important;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .actionContainer {
        height: calc(20px + 1.5rem);
        width: calc(20px + 1.5rem);
        display: flex;
        align-items: center;
        justify-content: center;

        .action {
          height: 18px;
          width: 18px;

          &:hover {
            cursor: pointer;
            color: $primary-red;
          }

          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .expandedRow {
    background-color: $secondary-lightblue !important;
    border-bottom: 2px solid #b5b5b5 !important;

    .container {
      padding: 33px 40px 50px;

      .text {
        font-family: $h3-font-family;
        font-size: $h3-font-size;
        line-height: $h3-line-height;
      }

      .button {
        margin: 30px auto 20px auto;
      }
    }
  }

  .expandedRowParent {
    .actions {
      .expand {
        background-color: $primary-blue;
        color: $primary-white;
      }
    }
  }
}
