.react-datepicker {
  .react-datepicker__header {
    background-color: $primary-blue;
    border-radius: 0;
    border-bottom: none;

    &:not(.react-datepicker__header--has-time-select) {
      border-radius: 0;
    }
  }

  .react-datepicker__day-name {
    color: white;
  }
}
