@import '../../../../styles/_variables';

.container {
  height: 100%;
  padding: 20px 25px;

  &.finalJury {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .jurorList {
    height: 60%;
    list-style: none;
    padding: 0 0 15px 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    .jurorStat {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 10% 40% 40% 10%;
      align-items: center;
    }
  }

  .groupContainer,
  .awardContainer {
    height: 20%;
    display: grid;
    padding-top: 15px;
    grid-template-columns: 10% 40% 40% 10%;
    align-items: center;

    &.finalJury {
      height: 60px;
      padding-top: 0;
    }
  }

  .groupContainer {
    border-top: 1px solid $secondary-lightgrey;

    &.finalJury {
      border-top: none;
    }
  }

  .imageContainer {
    height: 40px;
    width: 40px;

    .image {
      max-height: 100%;
      max-width: 100%;
      border-radius: 100%;
      object-fit: cover;
      width: 100%;
    }

    .iconContainer {
      height: 100%;
      width: 100%;
      background-color: #d8d8d8;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        color: $secondary-darkgrey;
      }
    }
  }

  .textContainer {
    font-family: $copy-font-family;
    font-size: $copy-font-size;
    line-height: $copy-line-height;

    .name {
      font-weight: $font-weight-bold;
      color: $primary-black;
    }
    .role {
      padding: 5px;
      font-weight: $font-weight-regular;
      color: $secondary-darkgrey;
    }
  }

  .progressPercentage {
    justify-self: end;
    font-family: $copy-l-font-family;
    font-size: $copy-l-font-size;
    line-height: $copy-l-line-height;
    color: $primary-black;
    font-weight: $font-weight-bold;
  }
}
