@import '../../styles/_variables';
@import '~bootstrap/scss/bootstrap';

.footer {
  background-color: $secondary-lightbeige;
  padding: 30px 0;
  flex-shrink: 0;
  .footerCol {
    display: flex;
    flex-direction: column;
    &:not(:first-of-type) {
      margin-top: 16px;
    }

    @include media-breakpoint-up(md) {
      &:not(:first-of-type) {
        margin: 0;
      }
    }

    .footerHeadline {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .footerHeadline,
    .footerLink {
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      line-height: $copy-line-height;
      text-decoration: none;
      color: $primary-black;
    }

    .contactBlock * {
      font-family: $copy-font-family;
      font-size: $copy-font-size;
    }
  }

  .version {
    font-family: $copy-font-family;
    font-size: $copy-font-size;
    color: $secondary-darkgrey;
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
}
