@import '../../styles/_variables';

.meetingsList {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    height: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $secondary-lightgrey;
    }

    .meetingItem {
      display: flex;
      flex-direction: row;
      text-decoration: none;
      height: 100%;

      .timeContainer {
        width: 83px;
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $dark-blue;
        color: white;

        &.highlight {
          background-color: $primary-red;
        }

        .date {
          font-family: $copy-xs-font-family;
          font-size: $copy-xs-font-size;
          line-height: $copy-xs-line-height;
          font-weight: $font-weight-bold;
        }

        .time {
          font-family: $h2-font-family;
          font-size: $h2-font-size;
          line-height: $h2-line-height;
          font-weight: $font-weight-bold;
        }
      }

      .descriptionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 24px;
        flex-grow: 1;
        color: black;

        .name {
          font-family: $copy-font-family;
          font-size: $copy-font-size;
          line-height: $copy-line-height;
          font-weight: $font-weight-bold;
          padding: 0;
          margin: 0;
        }

        .info {
          font-family: $copy-s-font-family;
          font-size: $copy-s-font-size;
          line-height: $copy-s-line-height;
          font-weight: $font-weight-regular;
        }
      }

      .iconContainer {
        margin: auto;
        padding: 0 20px;

        .icon {
          color: $primary-red;
          height: 24px;
          width: 24px;
        }
      }

      &:hover {
        cursor: pointer;

        .descriptionContainer {
          text-decoration: underline;
        }
      }
    }
  }
  .placeholderContainer {
    cursor: default;
    display: flex;
    align-items: center;
    padding-left: 15px;

    .placeholderItem {
      height: 40%;
    }
  }
}
