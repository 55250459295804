@import '../../styles/_variables';

.textEditor {
  margin: 30px 0;

  .toolbar {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;

    .button {
      border: none;
      background-color: transparent;
      border-radius: 3px;

      &:hover {
        background-color: $secondary-lightgrey;
      }

      &.active {
        background-color: $secondary-lightgrey;
      }
    }
  }

  .textFieldContainer {
    background-color: $secondary-lightgrey;
    padding: 10px 10px 10px 10px;
    border-bottom: 2px solid $primary-blue;

    .labelContainer {
      height: 15px;
      position: relative;

      .label {
        position: absolute;
        top: 15px;
        left: 0px;
        color: $secondary-grey;
        transition: 0.2s;

        &.required {
          &:before {
            content: '* ';
          }
        }

        &.small {
          top: 0px;
          font-size: 10px;
          color: $primary-black;
        }
      }
    }

    .textField {
      height: 300px;
      max-height: 300px;
      overflow: auto;
    }
  }

  &.error {
    border-color: $primary-red;

    .textFieldContainer {
      background-color: rgba($tertiary-pink, 0.2);

      .labelContainer {
        .label {
          color: $primary-red;
        }
      }
    }
  }
}
