@import '../../styles/_variables';

.groupsTable {
  margin-top: 20px;

  table {
    height: 100%;
    border-collapse: separate; 
    border-spacing: 0;

    thead {
      th {
        padding: 8px 0;
        border-bottom: 1px solid $primary-black !important;
      }
    }
  }

  td {
    padding: 15px 0 !important;
    border-left: none;
    border-right: none;
    vertical-align: middle;
  }

  .jurorName {
    text-decoration: none;
    font-weight: $font-weight-bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
