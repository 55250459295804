@import '../../styles/_variables';

.avatar {
  display: flex;
  align-items: center;

  .img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 24px;
    background-color: $secondary-grey;
  }

  .name {
    display: block;
    font-weight: $font-weight-bold;
    color: $primary-blue;
  }

  .role {
    display: block;
    color: $secondary-darkgrey;
  }
}