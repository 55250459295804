@import '../../../../styles/_variables';

.iconContainer {
  position: relative;

  .icon {
    margin-right: 25px;

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .ecoCommentMarker {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-red;
    height: 2rem;
    top: -1.5rem;
    right: 0.5rem;
    width: 2rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1rem;
    border-radius: 50%;
    color: white;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }

    svg {
      font-size: 17px;
    }
  }
}
