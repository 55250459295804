@import '../../../styles/_variables';

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.meetingForm {
  .dateTimeLabel {
    display: flex;
    align-items: center;
    font-size: $copy-font-size;
    line-height: $copy-line-height;
    font-weight: $font-weight-regular;
  }

  .timeInput {
    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }

  .checkboxRow {
    padding-top: 20px;
  }

  .buttonRow {
    padding-top: 30px;
    padding-bottom: 30px;

    &.editButtons {
      .button {
        margin-left: auto;
      }
    }

    &.addButtons {
      .addButton {
        margin-left: auto;
      }
    }
  }
}
