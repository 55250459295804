@import '../../../styles/_variables';

.additionalInformation {
  padding: 50px 0;

  .tagContainer {
    padding: 0 45px;
  }

  .companyGroupTitle {
    font-size: $copy-s-font-size;
    font-weight: $font-weight-bold;
    font-family: $copy-s-font-family;
    display: block;
    margin: 20px 0 10px 0;
  }
}