@import '../../styles/_variables';

.dialogFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -20px !important;
}

.informationTable {
  td {
    vertical-align: middle;
  }

  .inactive {
    td {
      opacity: 0.5;
    }
  }
}

.attachmentIcons {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  svg {
    height: 16px;
  }
}

.status {
  text-transform: capitalize;
  &.published {
    color: $tertiary-petrol;
  }

  &.inactive {
    color: $secondary-grey;
  }
}

.actionsCell {
  padding-right: 5px;
  opacity: 1 !important;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 25px;

    .action {
      height: 18px;

      &:hover {
        cursor: pointer;
        color: $primary-red;
      }
    }
  }
}

.orderCell {
  opacity: 1 !important;
  padding-left: 0 !important;

  button:hover {
    color: $primary-red;
  }
}

.orderButtons {
  display: flex;
  flex-direction: row;
  gap: 5px;

  button {
    background-color: transparent;
    border: none;
  }
}

.informationTable {
  margin-bottom: 30px;
  .sortIcon {
    margin-left: 8px;
  }
}
