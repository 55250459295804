@import '../../styles/_variables';

.formGroup {
  width: 100%;
  position: relative;
  margin: 15px 0;

  .formControl {
    height: 50px;
    width: 100%;
    font-size: $copy-font-size;
    font-family: $copy-font-family;
    padding: 21px 15px 9px 15px;
    border-radius: 0;
    border: none;
    background-color: $secondary-lightgrey;
    border-bottom: 2px solid $primary-blue;
    transition: 0.2s;

    &:disabled {
      background-color: $secondary-lightgrey;
    }

    &.whiteBackground {
      background-color: $primary-white;
      &:disabled {
        background-color: $primary-white;
      }
    }

    &:hover,
    &:disabled:hover {
      background-color: $primary-blue;
      color: $primary-white;

      ~ .label {
        color: $primary-white;
      }

      &:focus,
      &:valid {
        ~ .label {
          color: $primary-white;
        }
      }
    }
    &:focus,
    &:valid,
    &:disabled {
      outline: none;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid $primary-blue;
      ~ .label {
        font-size: $copy-xs-font-size;
        bottom: 30px;
      }
    }

    &:focus {
      ~ .label {
        color: $primary-black;
      }
    }

    &.visited {
      &:invalid {
        background-color: rgba($tertiary-pink, 0.2);
        border-color: $tertiary-pink;
        ~ .label {
          color: $primary-red;
        }
      }
    }
  }
  .label {
    bottom: 12px;
    left: 12px;
    position: absolute;
    background-color: transparent;
    padding: 0px 5px 0px 5px;
    color: $secondary-grey;
    font-size: $copy-font-size;
    font-family: $copy-font-family;
    transition: 0.2s;
    pointer-events: none;
    &.required {
      &:before {
        content: '* ';
      }
    }
  }
}
