@import '../../../styles/_variables';

.container {
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  position: relative;
  display: flex;
  flex-direction: column;

  .header {
    padding: 30px;
    border-bottom: 1px solid $secondary-lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
