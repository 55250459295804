@import '../../../../styles/_variables';

.sessionDetail {
  .navigationContainer {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: $primary-red;
      text-transform: uppercase;
    }
  }

  .sessionDetailContainer {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $secondary-lightbeige;
    display: flex;
    justify-content: center;

    .formHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;

      .headerText {
        font-family: $h2-font-family;
        font-size: $h2-font-size;
        line-height: 22px;
      }

      .editButton {
        background-color: transparent;
        border: none;
        height: fit-content;

        &:hover {
          svg {
            color: $primary-red;
          }
        }
      }
    }

    .formWrapper {
      min-width: 520px;
      max-width: 800px;
      width: 50%;
    }
  }

  .tabView {
    margin-top: 20px;
    margin-bottom: 40px;

    .tabNavigationSection {
      padding-top: 20px;
      margin-bottom: 22px;
      .navigationLinksContainer {
        padding-left: 1.1rem;
        padding-right: 0.4rem;
        & > div {
          display: flex;
          flex-direction: row;
          gap: 48px;
          border-bottom: 1px solid $secondary-darkgrey;
          .headerLinks {
            position: relative;
            top: 0px;
            font-family: $button-1-font-family;
            font-size: $button-1-font-size;
            line-height: $button-1-line-height;
            letter-spacing: $button-1-letter-spacing;
            color: $primary-black;
            cursor: pointer;
            padding-left: 7px;
            padding-right: 7px;
            text-transform: uppercase;

            &.active {
              font-weight: $font-weight-bold;
              border-bottom: 4px solid $primary-blue;
            }
          }
        }
      }
    }

    .buttonRow {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
}
