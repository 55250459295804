@import '../../styles/_variables';

.groupsTable {
  margin-top: 50px;
  .sortCaret {
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }
}

.progressBarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progressValue {
  width: 80px;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  display: flex;
  flex-shrink: 0;
}

.actionsWrapper {
  .actions {
    text-align: right;

    > svg {
      width: 22px;
      height: 18px;
      cursor: pointer;

      &:hover {
        color: $primary-red;
      }
    }
  }
}

.jurorRow {
  display: flex;
  align-items: center;
  background-color: $secondary-lightblue;

  > div {
    padding: 0.75rem 0.5rem;

    &.nameColumn {
      font-family: $copy-font-family;
      font-size: $copy-font-size;
      line-height: $copy-line-height;
      .name {
        color: $primary-black;
        font-weight: $font-weight-bold;
        padding-right: 5px;
      }
      .role {
        color: $secondary-darkgrey;
      }
    }
    &.nameColumn {
      width: 22%;
    }
    &.evaluationCount {
      width: 8%;
    }
    &.awardedSpacer,
    &.goldSpacer {
      width: 8%;
    }
    &.progressColumn {
      width: 25%;
    }
    &.scoreColumn {
      width: 25%;
    }
    &.actionsSpacer {
      width: 4%;
    }
  }
}
