@import '../../../styles/_variables';
@import '~bootstrap/scss/bootstrap';

.widgetContainer {
  background-color: $primary-white;
  box-shadow: 0 2px 16px 0 rgba(199, 199, 223, 0.36);
  height: 265px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  transition: height 0.5s;

  &.finalJury {
    height: 283px;
  }

  &.expanded {
    height: 434px;
  }

  .title {
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }

  .jurorStatsContainer {
    padding-bottom: 20px;
    height: 100%;
  }

  .carousel {
    height: 100%;

    .carouselItem {
      height: 100%;
      padding: 0 30px 30px 30px;

      .itemContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .prevIcon {
      padding-left: 15px;
    }

    .nextIcon {
      padding-right: 15px;
    }

    &.withIndicators > div:first-child {
      button {
        width: 5px !important;
        height: 5px !important;
        border-radius: 10px;
        margin: 0 10px !important;
        background-color: $secondary-lightgrey !important;
        border: none !important;

        &[aria-current='true'] {
          background-color: $secondary-darkgrey !important;
        }
      }
    }
  }
}
