@import '../../../../styles/_variables';

.tagRow {
  padding: 10px 0;
  border-bottom: 1px solid $primary-black;

  &:first-of-type {
    border-top: 1px solid $primary-black;
  }

  .tagKey {
    padding: 0;
    font-family: $copy-s-font-family;
    font-size: $copy-s-font-size;
    font-weight: 500;
  }

  .tagValue {
    font-family: $copy-s-font-family;
    font-size: $copy-s-font-size;
    font-weight: 700;
  }
}