@import '../../../../styles/_variables';

.messagesContainer {
  overflow-y: auto;
  height: 244px;
  padding: 20px 30px;

  &.wholePage {
    padding: 20px 30px 20px 0;
    min-height: 244px;
    height: calc(100vh - 560px); // navi, footer, input & hero
  }

  .messageContainer {
    display: flex;
    margin-bottom: 10px;

    &.right {
      display: flex;
      justify-content: flex-end;

      .message {
        background-color: $secondary-lightblue;
      }
    }

    .profileIconContainer,
    .img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      object-fit: cover;
    }
    .message {
      background-color: $secondary-lightbeige;
      padding: 10px;
      border-radius: 5px;

      .name,
      .date {
        font-size: $copy-s-font-size;
      }

      .name {
        font-weight: $font-weight-bold;
      }

      .text {
        font-size: $copy-font-size;
      }
    }
  }
}

.form {
  padding: 20px 30px 10px 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.wholePage {
    padding-left: 60px;
  }

  .input {
    width: 100%;
    border: 1px solid $secondary-lightgrey;
    padding: 10px;
    border-radius: 5px;
    font-size: $copy-font-size;
  }

  .submitBtn {
    background-color: transparent;
    border: 0;
  }

  .submitIcon {
    color: $primary-red;
    width: 24px;
    height: 24px;
    margin-top: 10px;
  }
}
