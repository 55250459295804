@import '../../../styles/_variables';

.topNavigationWrapper {
  background-color: $primary-white;
  
  .topNavigation {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 40px 20px;

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .overviewLink {
        color: $primary-red;
        font-size: $copy-l-font-size;
        font-family: $copy-font-family;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }

    .left,
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $copy-font-family;
      font-weight: $font-weight-bold;
      font-size: $copy-l-font-size;
      color: $primary-black;
      text-decoration: none;
      text-transform: uppercase;

      .topNavIcon {
        width: 18px;
        height: 26px;
      }

      &.hidden {
        opacity: .3;
        pointer-events: none;
      }
    }

    .left {
      .topNavIcon {
        margin-right: 7px;
      }
    }

    .right {
      .topNavIcon {
        margin-left: 7px;
      }
    }
  }
}
